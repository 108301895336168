@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
    --black-button: #231F20;
    --offwhite: #f4f4f4;
    --button-padding: 12px 43px;
}

.section1-container-navigate {
    height: 100%;
    width: 100%;
    font-family: 'Poppins', sans-serif;

    .nav-link-bar {
        font-weight: 400;
        // margin-left: 25px;
    }

    .nav-link-bar:hover {
        font-weight: 600;
    }

    // .me-auto {
    //     padding-left: 50px;
    // }
    .login-button {
        display: inline-flex;
        padding: 11px 30px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 10px;
        background-color: var(--black-button);
        color: var(--white);
    }
}

.logo {
    height: 60px;
}


.header-design-elements1 {
    position: absolute;
    padding-left: 40%;
    padding-top: 95px;

    .design-element1 {
        transform: rotate(27.908deg);
    }
}

.header-design-elements2 {
    position: absolute;
    padding-top: 25%;

    .design-element2 {
        transform: rotate(-9.294deg);
    }
}

.header-design-elements3 {
    float: right;
    padding-top: 45%;
    margin-right: 5px;

    .design-element3 {
        transform: rotate(-19deg);
    }
}



@media only screen and (max-width: 800px) {
    .header-design-elements1 {
        padding-left: 55%;
    }

    .header-design-elements2 {
        padding-top: 50%;
    }
}

@media only screen and (max-width: 577px) {
    .header-design-elements3 {
        padding-top: 70%;
    }

    .header-design-elements2 {
        display: none;
    }
}

.banner-container {
    padding-left: 12%;
    padding-top: 210px;
    padding-right: 12%;
    position: relative;

    @media only screen and (max-width: 1200px) {
        .cover-image {
            flex-direction: column;
            align-items: center;
        }
    }

    .banner-header {
        color: var(--black-button);
        font-size: 44px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        // width: 588px;
        padding-bottom: 36px;

        @media only screen and (max-width: 400px) {
            font-size: 33px;
            padding-bottom: 30px;
        }
    }

    .banner-sub {
        color: #747373;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 33px;
        // width: 546px;
        padding-bottom: 36px;

        @media only screen and (max-width: 400px) {
            font-size: 16px;
            padding-bottom: 30px;
        }
    }
}

.button-black {
    display: inline-flex;
    padding: var(--button-padding);
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: var(--black-button);
    color: var(--white);
}

.button-white {
    margin-left: 48px;
    display: inline-flex;
    padding: var(--button-padding);
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    color: var(--black-button);
    background: var(--offwhite);
}

.condition-section {
    padding-top: 25px;
    display: flex;
}

.tickmark2 {
    padding-left: 20px;
}

.condition-text {
    padding-left: 7px;
    color: #747373;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

@media only screen and (max-width: 1200px) {
    .banner-header {
        font-size: 38px;
        width: 400px;
    }
}
@media only screen and (max-width: 709px) {
    .banner-footer{
        display: flex;
        flex-direction: column;

        .login-button{
            margin-left: 50px;
            width: 88%;
        }
    }
    .banner-container .button-white {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 680px) {
    .banner-container {
        padding-top: 150px;
    }

    .banner-header {
        font-size: 30px;
        width: 90%;
    }

    .banner-sub {
        width: 80%;
    }
}

@media only screen and (max-width: 500px) {
    .banner-container .button-white {
        margin-top: 20px;
    }

    .tickmark2 {
        padding-left: 0;
    }
}

@media only screen and (max-width: 455px) {
    .banner-container .banner-footer {
        display: flex;
        flex-direction: column;
    }

    .banner-container .button-white {
        margin-top: 20px;
        margin-left: 0;
    }

    .banner-container .login-button {
        margin-left: 0;
        width: 100%;
    }

    .banner-header {
        font-size: 25px;
    }

    .banner-sub {
        width: 95%;
        font-size: 13px;
        line-height: 25px;
    }
}


@media only screen and (max-width: 400px) {
    .banner-header {
        font-size: 11px;
    }
}




.cover-image {
    width: 45%;
    margin-left: auto;
    position: absolute;
    top: 25%;
    left: 77%;
    transform: translateX(-50%);
}

@media only screen and (max-width: 1025px) {
    .cover-image {
        position: absolute;
        width: 44%;
        top: 28%;
    }
}

@media only screen and (max-width: 769px) {
    .cover-image {
        width: 100%;
        margin-left: auto;
        margin-top: 30px;
        position: relative;
        top: 0;
        left: 0;
        transform: none;
    }
}

.partners-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 70px;
}

.partners-container img {
    padding-left: 20px;
    padding-right: 20px;
}

.partners-carousel {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.partners-item {
    width: 60%;
}

.service-container {
    height: 100%;
    width: 100%;
    background: #F0F0F0;
    font-family: 'poppins';
    padding-bottom: 50px;
}

.carousal-item {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.carousal-style {
    width: 60%;
    padding-top: 15px;
    padding-bottom: 20px;

    .partners-logo {
        height: 150px;
    }
}

.carousal-style h2 {
    padding-top: 13px;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.carousal-style p {
    color: #747373;
    font-style: normal;
    line-height: normal;
}

.service-title {
    text-align: center;
    padding-top: 70px;
}

.service-title h2 {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.service-title p {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 15px;
}

.rounded-lg {
    border-radius: 1rem !important;
}

.text-small {
    font-size: 0.9rem !important;
}

.custom-separator {
    width: 80%;
    height: 3px;
    background-color: #E7E7E7;
}

.text-uppercase {
    letter-spacing: 0.2em;
}

.billerQ-details {
    position: relative;
}

.biller-image {
    position: absolute;
    top: 17%;
    left: 65%;
    height: 500px;
}

.task-h2 {
    margin-top: -20px;
}

@media (max-width: 992px) {
    .biller-image {
        /* position: static; 
        margin-top: 50px; 
        width: 100%;
        max-width: 300px; 
        left: auto;  */
        position: relative;
        margin-top: 50px;
        height: 350px;
        /* width: 100%;
        max-width: 300px; */
        left: auto;

    }
}

@media (max-width: 600px) {
    .mobile-image {
        text-align: center;
    }
}

@media (max-width: 500px) {
    .design {
        display: none;
    }
}