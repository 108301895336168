:root {
    --white: #ffffff;
    --black-button: #231F20;
    --offwhite: #f4f4f4;
}

.client-container{
    .partners-container{
        height: 30%;
    }
    .section2-container {
        height: 100%;
        width: 100%;
        font-family: 'poppins';
    }
}

.design-elements4 {
    display: flex;
    justify-content: center;
    padding-top: 70px;
}

.productivity-container {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 50%;
}

.productivity-container h2 {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 20px;
}

.productivity-container p {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 15px;
    padding-bottom: 20px;
}

.productivity-button {
    display: flex;
    justify-content: center;
}

.blackBox {
    margin-top: 50px;
    margin-bottom: 60px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--theme-default);
    border-radius: 20px 50px 20px 20px;
}

.whiteBox {
    width: 550px;
    height: 80px;
    float: right;
    background-color: var(--white);
    -webkit-transform: skew(20deg);
    -moz-transform: skew(20deg);
    -o-transform: skew(20deg);
    transform: skew(20deg);
    margin-left: 25px;
}

.billerQ-details {
    padding-top: 180px;
    padding-left: 100px;
    padding-bottom: 50px;
}

.billerQ-details h2 {
    color: var(--offwhite);
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.billerQ-details p {
    color: var(--offwhite);
    font-style: normal;
    font-size: 18px;
    line-height: normal;
    width: 55%;
}

.billerQ-details-para {
    padding-top: 24px;
    padding-bottom: 24px;
    font-weight: 400;
}

.points-container {
    display: flex;
    align-items: center;
    padding-top: 10px;
}

.points-container img {
    flex-shrink: 0;
}

.points-container p {
    font-weight: 300;
    padding-left: 15px;
    margin-bottom: 0;
}

@media only screen and (max-width: 992px) {
    .billerQ-details p {
        width: 80%;
    }
}

@media only screen and (max-width: 766px) {
    .whiteBox {
        width: 300px;
    }
}

@media only screen and (max-width: 690px) {
    .billerQ-details{
        padding-left: 50px;
    }
    .productivity-container {
        width: 80%;
    }
    .productivity-container .button-white{
        margin-left: 15px;
    }
}

@media only screen and (max-width: 500px) {
    .productivity-button {
        flex-direction: column;
    }
    .productivity-container .button-white{
        margin-top: 15px;
        margin-left: 0;
    }
}

@media only screen and (max-width: 430px) {
    .whiteBox {
        display: none;
    }
    .blackBox{
        border-radius: 20px 20px 20px 20px;
    }
    .billerQ-details{
        padding-top: 50px;
    }
    .billerQ-details h2{
        font-size: 22px;
    }
    .billerQ-details p{
        font-size: 15px;
    }
}