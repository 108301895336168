:root {
    --white: #ffffff;
    --black-button: #181851;
    --blue-color: #6B4C9F;
    --offwhite: #f4f4f4;
}

.section1-containers {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.section1-containers {
    height: 100%;
    width: 100%;
    font-family: 'Poppins',sans-serif;
}

.container {
    flex: 1;
    padding: 2px 5px 7px;
}

.content-section {
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
}

.nav-link-bar {
    font-size: 58px;
    font-weight: 400;
    margin-left: 25px;
}

.nav-link:hover {
    font-weight: 600;
}

.login-buttons {
    display: inline-flex;
    padding: 11px 30px;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 10px;
    background-color: var(--black-button);
    color: var(--white);
 
}
.navbar-nav{
    font-size:13px ;
}

#responsive-navbar-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ml-auto {
    margin-left: auto;
}


.heading-main {
    margin-top: 70px;
    background-color: #181851;
    padding: 45px 0 20px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* z-index: 1; */
    min-height: calc(20vh - 70px);
}

.disclaimer-heading {
    font-size: 2vw;
    font-weight: bold;
    color: white;
    text-align: center;
    margin: 0;
}

.disclaimer-text {
    margin-top: 25px;
    font-size: 15px;
    text-align: left;
    color: #878181;
    line-height: 1.9;
}

.user-agreement-section {
    text-align: left;
    margin-top: 30px;
}

.sub-heading {
    text-align: left;
}

.disclaimer-list {
    margin-top: 10px;
    padding-left: 20px;
    list-style-type: disc;
    line-height: 1.9;
}

.disclaimer-nested-list {
    margin-top: 10px;
    padding-left: 20px;
    list-style-type: circle;
}

.disclaimer-list-item {
    margin-bottom: 5px;
    font-size: 15px;
    color: #878181;
}

@media (max-width: 991px) {
    .container {
        padding-left: 10px;
        padding-right: 10px;
    }

    .content-section {
        padding-left: 5px;
        padding-right: 5px;
    }
}

@media (max-width: 800px) {
    .disclaimer-heading {
        font-size: 5vw;
        z-index: 1;
    }
}

@media (max-width: 767px) {
    .heading-main {
        padding-top: 30px;
        padding-bottom: 15px;
        min-height: auto;
    }

    .disclaimer-heading {
        font-size: 28px;
    }

    .disclaimer-text {
        font-size: 14px;
    }

    .disclaimer-list-item {
        font-size: 14px;
    }
}

@media (max-width: 600px) {
    .heading-main {
        margin-top: 50px;
        padding-top: 50px;
        padding-bottom: 10px;
    }

    .container {
        padding-left: 5px;
        padding-right: 5px;
    }

    .disclaimer-text {
        font-size: 13px;
    }

    .disclaimer-list-item {
        font-size: 13px;
    }
}