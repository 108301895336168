:root {
    --white: #ffffff;
    --black-button: #181851;
    --offwhite: #f4f4f4;
  }

.section5-container {
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    font-family: 'poppins';
    padding-top: 40px;
}

.useCase-container .useCase-title {
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.useCase-carousal {
    // height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.useCase-carousal-Button{
    border-radius: 15px;
    box-shadow: 1px 1px 15px 1px rgba(0, 0, 0, 0.08);
    padding-left: 35px;
    margin-top: 20px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 40px;
    cursor: pointer;
}

.useCase-carousal:hover h2 {
    color: var(--theme-default);
}

.useCase-carousal-down {
    border-radius: 15px;
    box-shadow: 1px 1px 28px 1px rgba(0, 0, 0, 0.08);
    padding-left: 40px;
    margin-top: 50px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 40px;
}

.useCase-carousal-down:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    transition: 1s ease;
}

.useCase-carousal-style {
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 15px;
    border: 1px solid var(--black-button);
    box-shadow: 1px 1px 15px 1px rgba(0, 0, 0, 0.08);
    // padding-left: 35px;
    width: 95%;
    margin: 10px auto;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
}

.useCase-carousal-style .useCase-header{
    color: #231F20;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 10px;
}

.useCase-carousal-style h2 {
    color: #231F20;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 10px;
}
.mgTop{
    margin-top: 40px;
}

.useCase-carousal-style p {
    color: #747373;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.growth-section {
    border-radius: 25px;
    background: radial-gradient(50% 50.00% at 50% 50.00%, #453364 0%, #231F20 100%);
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
}

.growth-section h2 {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 189px;
    margin-right: 50px;
    margin-left: 30px;
}

.value-container {
    text-align: center;
    padding-right: 50px;
}

.growth-section p {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.num-value {
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 4px;
}

@media only screen and (max-width: 1030px) {
    .growth-section h2 {
        font-size: 27px;
    }

    .num-value {
        font-size: 40px;
    }

    .growth-section p {
        font-size: 15px;
    }
}

@media only screen and (max-width: 983px) {
    .growth-section h2 {
        font-size: 27px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .num-value {
        font-size: 40px;
    }

    .growth-section p {
        font-size: 15px;
    }
}

@media only screen and (max-width: 660px) {
    .growth-section h2 {
        font-size: 20px;
    }
    .value-container{
        padding-right: 25px;
    }
    .num-value {
        font-size: 27px;
    }
}

@media only screen and (max-width: 553px) {
    .growth-section h2 {
        font-size: 17px;
    }
    .num-value {
        font-size: 22px;
    }
    .growth-section p {
        font-size: 13px;
    }
}

@media only screen and (max-width: 450px) {
    .growth-section {
        flex-direction: column;
    }
}