/**=====================
    28. Box-layout CSS start
==========================**/
.box-layout {
  background: $light;
  background-blend-mode: overlay;
  @media only screen and (min-width: 1280px) {
    .das-order-1 {
      order: 1;
    }
    .box-col-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .box-col-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .box-col-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .box-col-7 {
      flex: 0 0 60%;
      max-width: 60%;
    }
    .box-col-5 {
      flex: 0 0 40%;
      max-width: 40%;
    }
    .box-col-8 {
      flex: 0 0 70%;
      max-width: 70%;
    }
    .box-col-4 {
      flex: 0 0 33.33%;
      max-width: 33%;
    }
    .box-col-20 {
      flex: 0 0 20%;
      width: 20%;
    }
    .box-col-25 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .box-col-30 {
      flex: 0 0 30%;
      max-width: 30%;
    }
    .box-col-33 {
      flex: 0 0 33.333%;
      max-width: 33.333%;
    }
    .box-col-35 {
      flex: 0 0 35%;
      max-width: 35%;
    }
    .box-col-40 {
      flex: 0 0 40%;
      max-width: 40%;
    }
    .box-col-50 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .box-col-60 {
      flex: 0 0 60%;
      max-width: 60%;
    }
    .box-col-65 {
      flex: 0 0 65%;
      max-width: 65%;
    }
    .box-col-70 {
      flex: 0 0 70%;
      max-width: 70%;
    }
    .box-col-100 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .xl-33-33 {
      flex: 0 0 33.333%;
      max-width: 33.333%;
    }
    .default-page,
    .ecommerce-page {
      .card-header {
        &.dflex {
          display: block;
        }
      }
      .card {
        .card-header {
          padding: 20px;
        }
        .card-body {
          padding: 20px;
        }
        .card-footer {
          padding: 20px;
        }
      }
    }
    .page-wrapper,
    &.page-wrapper {
      &.box-layout {
        padding-top: 40px;
        margin-top: 0 !important;
      }
      &.compact-wrapper {
        // margin-top: $box-layout-space;
        .page-body-wrapper {
          border-top-right-radius: 20px;
          border-top-left-radius: 20px;
          .sidebar-wrapper {
            width: 250px;
            top: $box-layout-space;
            border-bottom-left-radius: 20px;
            border-top-left-radius: 20px;
            height:100%;
            overflow: hidden;
            &.close_icon {
              ~ footer {
                width: 1280px;
                left: 50%;
                padding-left: 100px;
                margin-left: 0;
              }
              ~ .footer-fix {
                padding-left: 0;
              }
              .sidebar-main {
                .sidebar-links {
                  height: calc(100vh - 210px);
                  .simplebar-wrapper {
                    .simplebar-mask {
                      .simplebar-content-wrapper {
                        .simplebar-content {
                          .menu-box {
                            > ul {
                              > li {
                                a {
                                  padding: 12px 30px;
                                  svg {
                                    margin-right: unset;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ~ .page-body {
                margin-left: $sidebar-compact;
              }
              &:hover {
                width: 250px;
                .sidebar-main {
                  .simplebar-offset {
                    height: calc(100vh - 424px);
                  }
                  .sidebar-links {
                    height: calc(100vh - 386px);
                    .simplebar-wrapper {
                      .simplebar-mask {
                        .simplebar-content-wrapper {
                          .simplebar-content {
                            .menu-box {
                              > ul {
                                > li {
                                  a {
                                    svg {
                                      margin-right: 10px;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .logo-wrapper {
              box-shadow: 0 8px 6px -6px rgba(89, 102, 122, 0.1);
            }
            .sidebar-main {
              .simplebar-offset {
                height: calc(100vh - 424px);
              }
              .sidebar-links {
                margin-top: 10px;
                margin-bottom: 20px;
                height: calc(100vh - 386px);
                padding: 20px;
                .simplebar-wrapper {
                  .simplebar-mask {
                    .simplebar-content-wrapper {
                      .simplebar-content {
                        .menu-box {
                          > ul {
                            > li {
                              a {
                                padding: 12px 15px;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .page-body {
            min-height: calc(100vh - 142px);
            margin-top: 0;
            top: 80px;
            overflow: scroll;
            max-height: calc(100vh - 220px);
            padding-bottom: 65px;
            margin-left:$sidebar-compact;
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px $light-gray;
            }
            &::-webkit-scrollbar {
              width: 6px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: rgba(68, 102, 242, 0.15);
            }
          }
        }
        .page-header {
          .header-wrapper {
            .left-header {
              display: inline-block;
            }
          }
        }
        .simplebar-content-wrapper {
          .simplebar-content {
            padding: 0 !important;
          }
        }
      }
      &.horizontal-wrapper {
        &:before {
          display: none;
        }
        .page-body-wrapper {
          margin-top: 40px;
          .page-body {
            margin-top: 80px;
            min-height: calc(100vh - 310px);
            max-height: calc(100vh - 230px);
            overflow: scroll;
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px $light-gray;
            }
            &::-webkit-scrollbar {
              width: 6px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: rgba(68, 102, 242, 0.15);
            }
          }
          .footer {
            padding-left: 15px;
          }
          .sidebar-wrapper {
            width: 1280px;
            top: 80px;
            margin: 0;
            overflow-x: unset;
            position: relative;
            .sidebar-main {
              #sidebar-menu {
                left: 0%;
                transform: translate(0, 0);
                top: 125px;
                background-color: $white;
              }
            }
          }
        }
        .page-header {
          padding-left: 0;
        }
      }
      .page-header {
        max-width: 1280px;
        margin: 0 auto;
        left: 0;
        right: 0;
        transition: $sidebar-transition;
        padding-left:$sidebar-compact;
        margin-top: $box-layout-space;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        width: unset;
        &.close_icon {
          padding-left:$sidebar-compact;
          margin-left: auto;
          width: calc(100% - 0px);
          transition: $sidebar-transition;
        }
        .header-wrapper {
          border-top-right-radius: 20px;
        }
      }
      &.horizontal-wrapper {
        .page-header {
          .header-wrapper {
            .left-header {
              flex: 0 0 24%;
              max-width: 24%;
            }
            .nav-right {
              flex: 0 0 63%;
              max-width: 63%;
            }
          }
        }
      }
      .page-body-wrapper {
        width: 1280px;
        box-shadow: 4px 11px 25px rgba(0, 0, 0, 0.07);
        margin: 0 auto;
        overflow: hidden;
        .learning-comment {
          margin-left: -14px !important;
          float: left !important;
        }
        .todo {
          .todo-list-wrapper {
            #todo-list {
              li {
                .task-responsive {
                  min-width: 1087px;
                  overflow: auto;
                }
              }
            }
          }
        }
        .active-order-table {
          max-width: 443px;
          overflow: auto;
          table {
            tbody {
              tr {
                td {
                  p {
                    width: 100px;
                  }
                }
              }
            }
          }
        }
        .activity {
          .d-flex {
            .gradient-round {
              &.gradient-line-1 {
                &:after {
                  height: 57px;
                  bottom: -64px;
                }
              }
              &.small-line {
                &:after {
                  height: 36px;
                  bottom: -43px;
                }
              }
              &.medium-line {
                &:after {
                  height: 40px;
                  bottom: -48px;
                }
              }
            }
          }
        }
        footer {
          width: 1280px;
          margin: 0 auto;
          padding-left: 100px;
          margin-bottom: 40px !important;
          position: fixed;
          left: 50%;
          transform: translate(-50%, 0);
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
        }
        .blockquote-footer {
          margin-left: 0;
          width: 100%;
        }
        .footer-fix {
          padding-left: 275px;
        }
        .chat-box {
          .chat-history {
            .call-content {
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 300px;
              > div {
                z-index: 8;
                background-color: rgba(255, 255, 255, 0.75);
                background-blend-mode: overlay;
                width: 100%;
                padding: 10px;
                left: 15px;
              }
              button {
                width: 40%;
                font-size: 14px;
                margin: 0 auto;
              }
            }
            .call-icons {
              margin-top: 20px;
              margin-bottom: 20px;
              ul {
                li {
                  border: 1px solid #717171;
                  width: 50px;
                  height: 50px;
                  padding: 8px;
                }
              }
            }
            .receiver-img {
              margin-top: 30px;
              margin-bottom: 30px;
              img {
                width: 38%;
              }
            }
            .total-time {
              h2 {
                font-size: 28px;
                color: #717171;
              }
            }
          }
        }
        canvas {
          &#myLineCharts {
            width: 100%;
          }
        }
        .chat-right-aside {
          flex: 0 0 60%;
          max-width: 60%;
          overflow: hidden;
        }
        .caller-img {
          position: absolute;
          width: 100%;
          max-width: 100%;
          left: 15px;
          img {
            opacity: 0.7;
          }
        }
        .browser-widget {
          img {
            height: 65px;
          }
        }
        .weather-widget-two {
          .bottom-whetherinfo {
            .whether-content {
              top: 39px;
            }
          }
        }
        .custom-card {
          .card-header {
            img {
              margin-top: -73px;
            }
          }
          .card-profile {
            img {
              height: 130px;
              top: -17px;
            }
          }
        }
        .select2 {
          width: 901.781px;
        }
      }
      ul {
        &.close_icon {
          > li {
            label {
              padding: 0;

              &:after {
                display: none;
              }
            }
          }
        }
      }
      .bookmark {
        ul {
          margin-right: -1px;
        }
      }
      .btn-group-showcase {
        .btn-radio {
          .btn-group {
            .radio {
              input[type="radio"] {
                display: none;
              }
            }
          }
        }
      }
      .d-flex.bitcoin-graph {
        display: block;
        .top-bitcoin {
          display: inline-block;
          vertical-align: middle;
        }
        .flex-grow-1 {
          margin-top: 30px;
          .bitcoin-content {
            .bitcoin-numbers {
              h6 {
                font-size: 14px;
              }
            }
            &.text-end {
              text-align: center !important;
            }
          }
        }
      }
      .alert {
        &.inverse {
          p {
            max-width: 238px;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .auth-bg-effect {
        .second-effect {
          left: 55%;
        }
      }
      .auth-bg-video {
        video {
          min-width: 67%;
          width: 67%;
        }
      }
      .pricing-wrapper-card {
        padding: 50px 20px;
      }
      .card {
        .blog-box {
          &.blog-grid {
            &.set-min-height {
              min-height: 400px;
            }
          }
        }
      }
      .chat-box {
        .chat-right-aside {
          .chat {
            .chat-header {
              .chat-menu-icons {
                li {
                  a {
                    i {
                      font-size: 19px;
                    }
                  }
                }
              }
            }
          }
          flex: 0 0 100%;
          max-width: calc(100% - 12px);
          overflow: hidden;
          border-radius: 15px;
        }
        .toogle-bar {
          display: inline-block;
          margin-right: 0 !important;
        }
      }
      .chat-menu {
        right: 0;
        border-top: 1px solid #ddd;
        opacity: 0;
        transform: translateY(-30px);
        visibility: hidden;
        top: 62px;
        position: absolute;
        z-index: 8;
        transition: all linear 0.3s;
        &.show {
          opacity: 1;
          visibility: visible;
          transform: translateY(0px);
          transition: all linear 0.3s;
          padding-bottom: 25px;
        }
      }
      .ct-10.total-chart {
        .ct-chart-bar {
          .ct-series {
            .ct-bar {
              stroke-width: 23px !important;
            }
          }
        }
      }
      .social-app-profile {
        .box-col-4 {
          flex: 0 0 30%;
          max-width: 30%;
        }
      }
      .email-wrap {
        .email-body {
          .email-compose {
            .cke_contents {
              &.cke_reset {
                max-height: 165px;
              }
            }
          }
        }
        .email-right-aside {
          .email-body {
            .inbox {
              height: 644px;
            }
          }
        }
        .email-content {
          .email-top {
            .user-emailid {
              &:after {
                right: -10px;
              }
            }
          }
        }
      }
      .todo {
        .notification-popup {
          right: 320px;
        }
      }
      .touchspin {
        padding: 0 10px;
      }
      .comingsoon {
        video {
          min-width: 67%;
          width: 67%;
        }
      }
    }
    .page-wrapper {
      &.compact-wrapper {
        .page-body-wrapper {
          .according-menu {
            right: 15px;
          }
        }
      }
    }
    .dismiss-text{
      .alert{
        p{
          max-width: 185px !important;
        }
      }
    }
    .learning-block{
      .blog-box{
        &.blog-list{
          .blog-details{
            padding: 18px;
          }
        }
      }
    }  
    .bookmark-tabcontent{
      .details-bookmark{
        .bookmark-card{
          &.card{
            .title-bookmark{
              p{
                text-overflow:ellipsis;
                overflow:hidden;
                display: -webkit-box !important;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                white-space: normal;
              }
            }
          }
        }
      }
    }
    .calender-widget{
      .cal-img{
        height: 328px;
      }
    }
    .data-tables{
      .rdt_Table{
        .rdt_TableHead{
          .rdt_TableHeadRow{
            .rdt_TableCol{
              min-width: 160px;
              &:first-child{
                min-width: 80px;
              }
              &:nth-child(2), &:nth-child(5), &:nth-child(7){
                min-width: 200px;
              }
            }
          }
        }
        .rdt_TableBody{
          .rdt_TableRow{
            .rdt_TableCell{
              min-width: 160px;
              &:first-child{
                min-width: 80px;
              }
              &:nth-child(2), &:nth-child(5), &:nth-child(7){
                min-width: 200px;
              }
            }
          }
        }
      }
    } 
    .browser-table{
      table{
        tr{
          td{
            padding-top: 12px;
            padding-bottom: 12px;
            &:nth-child(2){
              min-width: 180px;
            }
          }
        }
      }
    }
    .todo-wrap{
      .todo-list-body{
        .task-container{
          div.d-flex{
            min-width: 240px;
            justify-content: flex-end;
          }
        }
      }
    }
    .product-page-main {
      .proorder-xl-3 {
        order: 3;
      }
    }
    .product-page-main{
      .pro-slide-right{
        img{
          height: auto;
        }
      }
    }
    .simplebar-mask {
      bottom: 18px;
    }
    .user-profile {
      .photos {
        ul {
          li {
            width: 26%;
          }
        }
      }
    }
    .simplebar-content-wrapper{
      padding-bottom: 15px;
    }
    .pro-slide-right{
      .slick-slider{
        .slick-list{
          height: 375px !important;
        }
      }
    }
    .product-wrapper{
      .product-sidebar{
        .filter-section {
          .card{
            .left-filter{
              .card-body{
                max-height: 700px;
                overflow: hidden auto;
              }
            }
          }
        }
      }
    }
    .social-widget-card{
      .d-flex{
        .flex-grow-1{
          margin-left: 12px;
        }
        h2{
          font-size: 20px;
        }
        .social-font{
          width: 48px;
          height: 48px;
        }
      }
    }
    .static-widget{
      margin-bottom: 26px;
      h2{
        font-size: 20px;
      }
    }
    .general-widget{
      .custom-profile{
        .card-profile{
          img{
            height: 200px;
          }
        }
      }
    }
    .custom-profile{
      .card-footer{
        > div{
          margin: 0;
          div{
            padding: 28px 10px;
          }
        }
        h6{
          font-size: 14px;
        }
      }
    }
    .order-widget{
      .new-order{
        .progress{
          margin-top: 14px;
        }
      }
    }  
    .testimonial{
      p{
        margin-top: 4px;
      }
    }
    .activity-media{
      .d-flex{
        padding: 12px 0;
      }
    }
    .widget-joins{
      .widget-card{
        padding: 18px 14px;
        .widget-icon{
          width: 32px;
          height: 32px;
          svg{
            width: 18px;
            height: 18px;
          }
          i{
            font-size: 24px;
          }
        }
        h5{
          font-size: 13px;
        }
        h6{
          font-size: 16px;
        }
      }
    }
    // dashboard 1
    .yearly-view{
      .card-header{
        h3{
          margin-bottom: 14px;
        }
      }
    }
    .beyo-line{
      .beyo-detail{
        .date-history{
          .date-lable{
            padding: 6px;
          }
        }
        ul{
          li{
            h2{
              font-size: 14px;
            }
            &:last-child{
              min-width: 48px;
            }
          }
        }
        > p{
          width: 70%;
        }
        h3{
          font-size: 18px;
          .badge{
            padding: 7px;
          }
        }
      }
    }
    .value-chart{
      .row{
        div[class*="col-"]{
          padding: 0;
        }
      }
      p{
        font-size: 14px;
      }
      h2{
        font-size: 16px;
      }
      .card-body{
        padding: 38px 12px !important;
      }
    } 
    .profile-greeting {
      h1{
        font-size: 26px;
      }
      p{
        font-size: 14px;
      }
      .greeting-img {
        img {
          height: 148px;
        }
      }
    }
    .upgrade-history{
      height: 235px;
    }
    .transaction-history {
      .card-body {
        overflow-x: none;
        tbody {
          tr {
            td {
              &:last-child {
                border-right: unset !important;
              }
              &:first-child {
                border-left: unset !important;
              }
            }
          }
        }
      }
    }
    .sale-chart{
      .card-body{
        padding: 25px 14px;
        .sale-detail{
          .icon{
            width: 36px;
            height: 36px;
            svg{
              height: 18px;
            }
          }
          .sale-content{
            margin-left: 10px;
            h3{
              font-size: 16px;
            }
            p{
              font-size: 14px;
            }
          }
        }
      }
    }
    .rated-product{
      .img-wrapper{
        img{
          width: 80px;
        }
      }
      .product-detail{
        h4{
          font-size: 13px;
        }
        h3{
          font-size: 16px;
          margin-bottom: 0;
        }
      }
    }
    .small-chart-view{
      &.sales-chart{
        svg{
          width: 100px;
          transform: translate(-10px, 0);
        }
      }
    }
    .order-history{
      .rdt_Table{
        .rdt_TableBody{
          .rdt_TableRow{
            .rdt_TableCell{
              min-width: 170px;
            }
          }
        }
      }
    } 
    .jkanban-container{
      .kanban-container{
        .react-trello-board{
          .smooth-dnd-container{
            &.horizontal{
              .react-trello-lane{
                width: 355px;
              }
            }
            &.vertical{
              width: 310px;
            }
          }
        }
      }
    }
    // file content
    .file-content {
      li{
        margin-right: 14px;
        .files-list{
          .file-left{
            padding: 12px 14px;
          }
        }
      }
      .folder {
        .folder-box {
          margin-bottom: 10px;
          margin-right: 0;
          .d-flex {
            display: block;
            text-align: center;
            margin-top: 5px;
          }
        }
      }
    }
    // kanban board
    .kanban-board {
      width: calc(100% / 2 - 30px) !important;
    }
    // ecommerce
    .grid-options {
      background-color: gray;
      ul {
        li {
          &:nth-child(3),
          &:last-child {
            display: none;
          }
        }
      }
    }
    .grid-options {
      ul {
        &:last-child {
          background-color: gray;
        }
      }
    }
    .product-box {
      .product-details {
        padding: 20px;
        h4 {
          font-size: 18px;
        }
      }
    }
    // email
    .email-wrap {
      .email-right-aside {
        .email-profile {
          .inbox {
            p {
              width: calc(100% - 400px);
            }
          }
        }
      }
    }
    // chat
    .call-chat-sidebar {
      max-width: 260px;
    }
    // notes
    .note {
      width: calc(33.33% - 30px);
    }
    .blog-box {
      &.blog-shadow {
        height: 410px;
      }
    }
    // job search
    .job-search {
      .d-flex {
        .flex-grow-1 {
          .job-apply-btn {
            position: unset;
            width: fit-content;
            margin-top: 5px;
            display: block;
          }
        }
      }
    }
  }
}
/**=====================
    28. Box-layout CSS ends
==========================**/