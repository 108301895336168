:root {
    --white: #ffffff;
    --black-button: #231F20;
    --offwhite: #f4f4f4;
    --button-padding: 12px 43px;
}

.form-style h3{
    color: var(--theme-default);
}

.otp-main-container {
    height: 88vh;
    width: 100%;
    font-family: 'poppins';
}

.otp-container {
    display: flex;
    padding-top: 7%;
    padding-bottom: 7%;
    justify-content: center;
}

.otp-background {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--theme-default);
    width: 45%;
    padding: 60px 0px;
    border-radius: 15px;
    text-align: center;
}

.otp-background .button-signup {
    display: inline-flex;
    padding: 12px 100px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    border: 0px;
    background: #FFF;
    color: var(--theme-default);
    font-weight: 500;
}

@media only screen and (max-width: 715px){
    .otp-background{
        width: 80%;
    }
}