/**========================
    52. Invoice CSS Start
=============================**/
.invoice {
  p {
    color:$black;
    // text-transform: capitalize;
  }
  .invo-header {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid $light-gray;
  }
  .table-striped {
    tbody {
      tr {
        &:nth-of-type(odd) {
          background-color: rgba($primary-color, 0.05);
          --bs-table-accent-bg: unset;
        }
        h6 {
          text-transform: capitalize;
          color: $primary-color;
        }
      }
    }
  }
  .invo-profile {
    margin-bottom: 15px;
    .invo-profile-left {
      .d-flex {
        .d-flex-left {
          margin-right: 15px;
        }
        .flex-grow-1 {
          h4 {
            font-size: 16px;
          }
          p {
            span {
              display: block;
            }
          }
        }
      }
    }
    .invo-profile-right {
      text-align: right;
      h6 {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}
.invoice-table {
  margin-top: 16px;
  margin-bottom: 16px;
}
.invoice {
  .text-end {
    input {
      margin-top: 6px;
    }
  }
  .d-flex {
    align-items: center;
  }
}
@media only screen and (max-width: 1199px) {
  .invoice {
    h4 {
      margin-bottom: 0px;
    }
    h3 {
      margin-bottom: 0px;
    }
    .invo-profile {
      .d-flex {
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid $light-gray;
      }
    }
    .invo-profile {
      .invo-profile-right {
        text-align: left;
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .invoice {
    .card {
      .card-body {
        padding: 20px;
      }
    }
    table {
      td {
        min-width: 150px;
        &:first-child {
          min-width: 280px;
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .invoice {
    .invo-pal {
      margin-top: 15px;
      text-align: left !important;
    }
    .text-md-end {
      text-align: right;
    }
  }
}
@media only screen and (max-width: 575px) {
  .invoice {
    .invo-header {
      .flex-grow-1 {
        text-align: left;
      }
    }
    .text-xs-center {
      margin-top: 20px;
      text-align: left !important;
    }
    .text-md-end {
      text-align: left;
    }
  }
}
@media only screen and (max-width: 420px) {
  .invoice {
    .invo-profile {
      .d-flex {
        display: block !important;
        .flex-grow-1 {
          margin-left: 0 !important;
          margin-top: 10px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .custom-col {
      // margin-top: 10px; 
      margin-bottom: 20px; 
  }
}




/**========================
    52.  Invoice CSS endp
==========================**/