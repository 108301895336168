.dashboard-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  
  }
  
  .box {
    width: 60%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    margin-bottom: 30px;
    margin-top: auto;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  