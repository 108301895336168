// SignIn
.signin-container {
    @media (max-width: 320px) {
        margin-top: -30px;
    }

    .text-section h1 {
        color: var(--theme-default);
        font-family: 'Poppins';
        font-size: 60px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        @media (max-width: 400px) {
            font-size: 50px;
            line-height: 60px;
        }

        @media (max-width: 320px) {
            font-size: 40px;
            line-height: 45px;

        }
    }

    .text-section p {
        color: #747373;
        font-family: 'Poppins';
        font-style: normal;
        line-height: normal;
    }

    .text-section {
        margin-left: 40px;

        .sub-text {
            font-size: 20px;
            width: 64%;
            font-weight: 400;

            @media (max-width: 400px) {
                font-size: 18px;
            }
        }

        .create-text {
            font-size: 18px;
            font-weight: 300;

            @media (max-width: 400px) {
                font-size: 15px;
            }

        }


        .button-signup {
            display: inline-flex;
            padding: 11px 43px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 10px;
            color: var(--theme-default);
            font-family: 'Poppins';
            font-size: 16px;
            font-weight: 500;
            background: #efefef;
            text-decoration: none;
        }

    }

    .signin-form-container1 {
        margin-left: 20px;
        margin-bottom: 20px;
        width: 80%;
        border-radius: 15px;
        background: var(--theme-default);

        @media (max-width: 500px) {
            margin: 40px auto;
            width: 90%;

            @media (max-width: 400px) {
                padding-top: 45px !important;
                margin-top: 40px;
                width: 90%;
            }
        }

        .signin-text {
            color: #FFF;
            font-size: 30px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-align: center;

            @media (max-width: 400px) {
                font-size: 28px;
            }
        }

        form input {
            background-color: #cbcbe2;
            border: none;
            font-size: 14px;
            border-bottom: 2px solid #a0aec0;
            box-sizing: border-box;
        }

        form button[type="submit"] {
            display: inline-flex;
            padding: 11px 40px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 10px;
            border-color: transparent;
            color: var(--theme-default);
            font-family: 'Poppins';
            font-size: 16px;
            font-weight: 500;
            background: #f4f4f4;
        }
    }

    .pd-top {
        padding-top: 100px;
        padding-bottom: 60px;
    }

    .form-group-Client {
        width: 90%;
        padding-left: 10%;

        @media (max-width: 500px) {
            flex-direction: column;
            align-items: flex-start;

            .link1 {
                margin-left: 0;
            }
        }


        .form-control {
            border: 1px solid #d0d0d0;
            background: rgba(244, 244, 244, 0.51);
        }

        .col-form-label {
            color: #eeeeee;
        }

        .show-hide {
            position: absolute;
            top: 24px;
            right: 20px;
            transform: translateY(-50%);

            span {
                cursor: pointer;
                font-size: 13px;
                color: #eeeeee;

                &.show {
                    &:before {
                        content: "show";
                    }
                }

                &:before {
                    content: "hide";
                }
            }
        }

        #checkbox1:checked+.text-muted1::before {
            content: "\2714";
            color: #8383b3;
            font-size: 17px;
            font-weight: bolder;

        }

    }

    .link1 {
        margin-left: auto;
        text-decoration: none;
        color: #eeeeee;
        display: inline-block;

        @media (max-width: 500px) {
            display: block;
            margin-left: 0;
            margin-right: 60%;


        }

        @media (max-width: 500px) {
            .text-muted1 {
                display: block;
                margin-left: 0;
                margin-right: 60%;

            }



        }
    }
}

.checkbox {
    display: flex;
    align-items: flex-start;

    @media (max-width: 500px) {
        display: inline;
        white-space: nowrap;
        margin-left: 0;
        margin-right: 46%;
    }

    @media (max-width: 450px) {
        padding-left: 15px;
    }

    @media (max-width: 350px) {
        padding-left: 45px;
    }
}



.text-muted1 {
    padding-left: 15px;
    color: #eeeeee;
}

.form-group-Client .link1 {
    margin-left: auto;
    text-decoration: none;
    color: #eeeeee;
    display: inline-block;

    @media (max-width: 500px) {
        display: inline-block;
        white-space: nowrap;
        margin-left: 0;
        margin-right: 60%;
    }

    @media (max-width: 450px) {
        padding-left: 16px;
    }

    @media (max-width: 350px) {
        padding-left: 32px;
    }
}



// SignUp
.signup-section {
    background: #f4f4f4;
    border-radius: 15px;
    padding-top: 50px;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: 'Poppins';
    margin-bottom: 70px;
}

.signup-section .signup-text {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    color: var(--theme-default);
}

.step-text {
    color: #9a9a9a;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
}

.service-form-container {
    margin-left: 20px;
    width: 80%;
    border-radius: 15px;
    background: var(--theme-default);
    padding-bottom: 40px;
    padding-top: 50px;
    padding-left: 30px;
}

.head-text {
    color: #FFF;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 20px;
    padding-bottom: 20px;
}

.service-details {
    padding-left: 25px;
    padding-right: 25px;
}

.single-service-container {
    display: flex;
}

.service-details h3 {
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.service-details p {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
}

.form-section {
    padding-left: 50px;
    padding-right: 50px;
}
@media (max-width: 767.98px) {
    .form-section {
        padding-left: 0px;
        padding-right: 0px;
    }
    
}
.form-section .form-style {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column
}

.form-section .button-next {
    display: inline-flex;
    padding: 12px 80px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: var(--theme-default);
    color: #FFF;
}

.otp-heading {
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.otp-sub {
    color: #747373;
    font-style: normal;
    line-height: normal;
}

.text-link {
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.select-industry {
    color: #2B2B2B;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.organ-reg-container .useCase-carousal-style img {
    height: 50px;
}

@media only screen and (max-width: 1200px) {
    .organ-reg-container .useCase-carousal-style img {
        height: 35px;
    }

    .organ-reg-container .useCase-carousal-style h2 {
        font-size: 17px;
    }

    .organ-reg-container .useCase-carousal-style p {
        font-size: 14px;
    }
}

@media only screen and (max-width: 992px) {
    .services-container {
        display: none;
    }

    // .row{
    //     display: flex;
    //     flex-direction: column;   //uncommenting will cause responsive issue in dashboard navbar
    // }
    .text-section {
        order: 1;
    }

    .signin-form-container1 {
        order: 2;
        margin-top: 20px;
    }

    .sub-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

}

.flex-containers {
    display: flex;
    justify-content: space-between;

}

.form-sections {
    flex: 1;
    // margin-top: 90px;
    // margin-left: 70px;
    // padding: 35px;
    // padding-bottom: 30px;
    // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    // border-radius: 10px;
    // height: 80vh;

}

.form-sections .button-next {
    display: inline-flex;
    padding: 12px 80px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: var(--theme-default);
    color: #FFF;
}

.services-containers {
    flex: 1;
    padding-left: 20px;
    margin-top: 90px;
    padding-bottom: 30px;
}

.otp-headings {
    text-align: center;
    font-size: 27px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 15px;
    padding-bottom: 10px;
}

// @media only screen and (max-width: 992px) {
//     .form-sections {
//         padding: 30px;
//         margin-right: 30px;
//     }
//     // .signup-section{
//     //     background: none;
//     // }
  

// }

@media only screen and (max-width: 792px) {
    .services-containers {
        display: none;
    }

}

.button-signups {
    display: inline-flex;
    padding: 11px 30px;
    border-radius: 10px;
    color: var(--theme-default);
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    background: #efefef;
    text-decoration: none;
}

.button-viewinvoice {
    display: inline-flex;
    padding: 11px 20px;
    border-radius: 10px;
    color: white;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    background: transparent; 
    border: 2px solid white; 
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s; 
    margin-left: 15px;
}

.button-viewinvoice:hover {
    background: white; 
    color: var(--theme-default); 
}


